<template>
  <div class="relative flex-1">
    <div class="flex flex-row w-full h-full">
      <div v-if="!detail" class="w-1/2 ml-2 mr-1 my-2 border-2 rounded-lg border-gray-200">
        <div class="flex flex-row">
          <div class="mt-5 px-4 text-blue-700 text-3xl">
            <i class="fal fa-user"></i>
          </div>
          <div class="pt-3 pl-2">
            <div class="text-gray-600 text-2xl font-bold">Customer</div>
            <div class="text-gray-600 text-sm">Search for customer in your CRM, or add a new customer.<br>&nbsp;</div>
          </div>
        </div>
        <div class="flex flex-col items-center mt-8 px-12">
          <div class="w-full">
            <vat-field v-on:found="foundVat"></vat-field>
            <div class="mt-2 rounded shadow-sm">
              <input v-model="companyName"  type="text" placeholder="Company name" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded sm:text-sm border-gray-300">
            </div>
          </div>
          <div class="mt-6 w-full">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-800">
                Or search with
              </span>
              </div>
            </div>
          </div>
          <div class="mt-6 w-full">
            <div class="mt-1 rounded shadow-sm">
              <input v-model="firstName" type="text" placeholder="First name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
            <div class="mt-1 rounded shadow-sm mt-2">
              <input v-model="lastName" type="text" placeholder="Last name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            </div>
            <div class="mt-1 flex flex-row mt-2">
              <div class="rounded-sm shadow-sm w-1 w-1/4">
                <input v-model="postCode"  placeholder="Postcode" class="w-full appearance-none block px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              </div>
              <div class="ml-2 rounded-sm shadow-sm w-3/4">
                <input v-model="city" type="text" placeholder="City" class="w-full appearance-none block px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              </div>
            </div>
          </div>
          <div class="mt-6 w-48 mb-6 flex flex-col items-center">
            <button @click="search" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
              <div v-if="loading"><span class="mt-1 mr-2"><span class="fas fa-spinner-third fa-spin"></span></span> Searching ...</div>
              <div v-else><span class="mt-1 mr-2"><span class="fas fa-search"></span></span> Search</div>
            </button>
            <a @click="reset" class="mt-2 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
              Reset
            </a>
          </div>
        </div>
      </div>

      <div v-if="!detail" class="w-1/2 ml-1 p-2 mr-2 my-2 border-2 rounded-lg border-gray-200">
        <div v-if="result.length===0 && !initial" class="border-2 border-red-500 rounded-lg bg-red-500 text-white p-4 font-bold text-center">
          No customers found
        </div>
        <div class="overflow-y-auto" style="max-height: 420px;">
          <div v-for="item in result" v-bind:key="item.id" class="group flex flex-row bg-gray-50 py-2 hover:bg-blue-200 border-2 border-white hover:border-blue-500 rounded-lg cursor-pointer" @click="select(item)">
            <div class="px-4 pt text-md text-gray-400 group-hover:text-blue-800">
              <i class="fal fa-long-arrow-right"></i>
            </div>
            <div v-if="nil(item.firstName) == '' && nil(item.lastName) == ''" class="pl-2 leading-5">
              <div class="font-medium">{{item.companyName}}</div>
              <div class="font-medium text-sm text-blue-900 leading-6 flex flex-row pt-1">
                <div class="mr-4 text-xs text-gray-500">{{ item.companyTelephone }}</div>
              </div>
              <div class="font-light text-sm">{{ item.companyAddress }} {{ item.companyNumber }} {{ item.companyBox }}</div>
              <div class="font-light text-sm">{{ item.companyPostCode }} {{ item.companyCity }}</div>
              <div class="font-light text-sm">{{ item.companyVat }}</div>
              <div class="text-sm text-gray-500 leading-6">{{ item.companyEmail }}</div>
            </div>
            <div v-else class="pl-2 leading-5">
              <div class="font-medium">{{ item.title }}. {{ item.firstName }}, {{ item.lastName }}</div>
              <div class="font-medium text-sm text-blue-900 leading-6 flex flex-row pt-1">
                <div class="mr-4 text-xs text-gray-500">{{ item.mobile }}</div>
                <div class="mr-4 text-xs text-gray-500">{{ item.telephone }}</div>
              </div>
              <div class="font-light text-sm">{{ item.address }} {{item.number}} {{ item.box }}</div>
              <div class="font-light text-sm">{{ item.postCode }} {{ item.city }}</div>
              <div class="text-sm text-gray-500 leading-6">{{ item.email }}
              </div>
            </div>
          </div>
        </div>
        <div class=" flex flex-row w-full justify-center">
          <div class="mt-6 w-48 flex flex-col items-center">
            <button @click="newCustomer" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
              <i class="fas fa-plus mt-1 mr-2"></i> new Customer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import {nil} from "@/common";
import vatField from '@/components/vat-field';
import {emptyCustomer} from "@/common/customer";

export default {
  name: "Customer",
  data() {
    return {
      initial: true,
      detail: false,
      loading: false,
      errorFlag: false,
      error: '',
      firstName: '',
      lastName: '',
      postCode: '',
      companyName: '',
      city: '',
      result: [],
      vatNumber: '',
    }
  },
  components: {
    'vat-field': vatField
  },
  methods: {
    nil: nil,
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    },
    found(items) {
      this.initial = false;
      this.result = items;
    },
    foundVat(items) {
      if (items.length === 0) return;
    },
    search() {
      if(this.loading) return;
      this.errorFlag = false;
      this.loading = true;
      axios.get('/manager/seller/sales/search/', {
        params: {
          first_name: this.firstName,
          last_name: this.lastName,
          post_code: this.postCode,
          company_name: this.companyName
        }, timeout: 10000
      }).then(response => {
        if (response.status !== 200) {
          this.initial = false;
          this.result = [];
          return;
        }
        this.initial = false;
        this.result = _.map(response.data.individuals, (i) => {
          return {
          title: nil(i.title),
          culture: 'FR',
          city: nil(i.address.localityName),
          address: nil(i.address.street),
          number: nil(i.address.number),
          box: nil(i.address.box),
          postCode: nil(i.address.zip),
          firstName: nil(i.firstName),
          lastName: nil(i.lastName),
          email: nil(i.email),
          telephone: nil(i.phone),
          mobile: nil(i.mobile),
          companyCity: "",
          companyAddress: "",
          companyNumber: "",
          companyBox: "",
          companyPostCode: "",
          companyName: "",
          companyVat: "",
          companyEmail: "",
          companyTelephone: "",
          vatTaxable: "0",
          vatCorporation: false,
          vatCorporation100: false,
          vatTaxableValue: null,
          vatDiplomatic: false,
          vatBelgian: true
        }});
      }).catch(err => {
        console.log(err)
        this.errorFlag = true;
        this.error = 'Time out loading customers';
      }).finally(() => {
        this.loading = false;
      });
    },
    newCustomer() {
      let customer =  emptyCustomer();
      customer.manual = true;
      this.$store.commit('seller/setCustomer', customer);
      this.$store.commit('tradein/setPart', 'vehicle-detail');
    },
    reset() {
      this.firstName = '';
      this.lastName = '';
      this.postCode = '';
      this.companyName= '';
      this.city= '';
    },
    select(customer) {
      this.$store.commit('seller/setCustomer', _.cloneDeep(customer));
      this.$store.commit('tradein/setPart', 'vehicle-detail');
    }
  },
  watch: {
  },
  created() {
    let search = this.$store.getters['tradein/customerSearch'];
    if(search) {
      this.vatNumber = this.$store.getters['tradein/customerSearch'].vatNumber;
      this.companyName = this.$store.getters['tradein/customerSearch'].companyName;
      this.firstName = this.$store.getters['tradein/customerSearch'].firstName;
      this.lastName = this.$store.getters['tradein/customerSearch'].lastName;
      this.postCode = this.$store.getters['tradein/customerSearch'].postCode;
      this.city = this.$store.getters['tradein/customerSearch'].city;
      this.search();
    }
  },
}
</script>

<style scoped>

</style>



